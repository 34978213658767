import { signIn, resetPassword } from '../../graphql/mutation/client'
import { Mutation } from 'react-apollo'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Image } from 'react-native-web'
import { signupStyle as styles } from './style'
import { currentClient } from '../../graphql/query/client'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { toggleSnackbar } from '../../actions/snackbar'

@connect(null, { toggleSnackbar })
export default class SigninForm extends Component {
  state = { language: 'en', password: '', email: '', showPassword: false }
  render() {
    const { onSignin } = this.props

    return (
      <Mutation
        mutation={signIn}
        onCompleted={onSignin}
        update={(cache, {data}) => {
          AsyncStorage.setItem('currentClient', JSON.stringify(data.login))
          AsyncStorage.setItem('access_token', data.login.access_token)
          cache.writeQuery({
            query: currentClient,
            data: { currentClient: data.login }
          })
        }}
      >
        {(signIn, { loading, error }) => (
          <View style={styles.container}>
            <form onKeyPress={e => this.onEnter(e, signIn)}>
              <Image style={{ width: 80, height: 80, margin: 'auto' }} source={require('./logo_only.png')} />
              {this.header()}
              {this.formFields()}
              {this.forgotPassword()}
              {error && <Text style={{color: 'red', textAlign: 'center', paddingTop: 15}}>Invalid email or password. Please try again.</Text>}
              {this.signinButton(loading, signIn)}
            </form>
          </View>
        )}
      </Mutation>
    )
  }

  forgotPassword = () => {
    const { email } = this.state
    return (
      <Mutation
        mutation={resetPassword}
        onCompleted={() => this.passwordComplete()}
      >
        {(resetPassword, { error }) => (
          <TouchableOpacity style={{ marginTop: 3, marginBottom: 10 }} onPress={() => resetPassword({ variables: { email } } )}>
            <Text style={{ alignSelf: 'flex-end', color: 'gray' }}>Forgot Password?</Text>
            {error && <Text style={{color: 'red', textAlign: 'center', paddingTop: 15}}>Please enter a valid email.</Text>}
          </TouchableOpacity>
        )}
      </Mutation>
    )
  }
  passwordComplete = () => {
    this.setState({ email: '' })
    this.props.toggleSnackbar('Password reset has been sent.')
  }

  formFields = () => {
    const { email, password, showPassword } = this.state
    return (
      <View style={{ paddingBottom: 10 }}>
        <View style={{ paddingVertical: 15 }}>
          <TextField
            label="Email"
            value={email}
            onChange={this.handleTextChange('email')}
            fullWidth
            style={{ paddingVertical: 10 }}
          />
        </View>
        <View style={{ paddingVertical: 10 }}>
          <TextField
            label="Password"
            value={password}
            type={showPassword ? 'text' : 'password'}
            onChange={this.handleTextChange('password')}
            fullWidth
            style={{ paddingVertical: 15 }}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                  >
                    {showPassword
                      ? <Visibility />
                      : <VisibilityOff />
                    }
                  </IconButton>
                </InputAdornment>
            }}
          />
        </View>
      </View>
    )
  }
  handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword })
  handleTextChange = name => event => {
    this.setState({ [name]: event.target.value })
  }
  onEnter = (e, submit) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      submit({ variables: { email: this.state.email, password: this.state.password } })
    }
  }
  handleChange = e => {
    this.props.changeLanguage(e.target.value)
    this.setState({ language: e.target.value })
  }
  header = () => (
    <View>
      <Text style={styles.headerTitle}>Welcome to optimumMe!</Text>
    </View>
  )
  signinButton = (loading, signin) => (
    <TouchableOpacity
      disabled={loading}
      style={loading ? styles.disable : styles.button}
      onPress={() => signin({ variables: { email: this.state.email.toLowerCase(), password: this.state.password } })}
    >
      {loading
       ? <ActivityIndicator size="small" />
       : <Text style={styles.btnText}>Sign In</Text>}
    </TouchableOpacity>
  )
}
