const { height, width } = Dimensions.get('window')

export default {
  workoutHeader: {
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white'
  },
  progressBar: {
    paddingBottom: 10
  },
  sessionContainer: {
    flex: 1,
  },
  sessionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    padding: 10
  },
  Morning: {
    backgroundColor: '#B9C586',
  },
  Afternoon: {
    backgroundColor: '#D59F84',
  },
  Evening: {
    backgroundColor: '#B880AD',
  },
  sessionTitle: {
    flexGrow: 2,
    color: 'white'
  },
  sessionDetails: {
    borderWidth: .5,
    borderColor: '#efefef',
    backgroundColor: 'white',
  },
  sessionTotal: {
    color: 'white',
    paddingRight: 5,
    letterSpacing: 1,
  },
  progressLabelLeft: {
    fontWeight: '600',
    fontSize: 16,
  },
  progressLabelRight: {
    fontSize: 18,
    fontWeight: '300',
  },
  workoutDate: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center'
  },
  datePicker: {
    textAlign: 'center'
  }
}
