import { Image } from 'react-native-web'
import { isAndroid, isIOS, isBrowser } from 'react-device-detect'
import style from './style'

export default class extends Component {
  render() {
    const { title, subtitle } = this.props
    return (
      <View style={style.container}>
        <Text style={style.title}>{title}</Text>
        <Text style={style.subtitle}>{subtitle}</Text>
        {this.renderDeviceBtn()}
        {isBrowser &&
          <TouchableOpacity onPress={() => this.props.history.push('/login')}>
            <Text style={style.webLinkTxt}>Continue on Web</Text>
          </TouchableOpacity>
        }
      </View>
    )
  }

  renderDeviceBtn = () => {
    if (isIOS) {
      return <IOSButton />
    } else if (isAndroid) {
      return <AndroidButton />
    } else {
      return (
        <View style={style.btnContainer}>
          <IOSButton />
          <AndroidButton />
        </View>
      )
    }
  }
}

const PlatformButton = ({uri, location}) => (
  <TouchableOpacity
    style={{margin: 10, alignSelf: 'center'}}
    onPress={() => window.location.href=location}
  >
    <Image
      style={{height: 50, width: 175}}
      source={{uri}}
    />
  </TouchableOpacity>
)

const IOSButton = () => (
  <PlatformButton
    location='https://itunes.apple.com/us/app/optimumme-v2/id1375721309?ls=1&mt=8'
    uri='https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/500px-Download_on_the_App_Store_Badge.svg.png'
  />
)

const AndroidButton = () => (
  <PlatformButton
    location= 'https://play.google.com/store/apps/details?id=com.optimumme'
    uri='https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/1024px-Google_Play_Store_badge_EN.svg.png'
  />
)
