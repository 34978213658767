export default {
  title: {
    fontSize: 20,
    fontWeight: '600',
    paddingBottom: 5
  },
  assessment: {
    marginVertical: 10,
    marginHorizontal: 20,
    padding: 20,
    borderWidth: 1,
    borderColor: 'gainsboro',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 5,
    width: 'calc(100% - 40px)',
    overflow: 'hidden',
    cursor: 'pointer'
  },
  score: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  scoreContainer: {
    width: 60,
    height: 60,
    backgroundColor: 'gainsboro',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10
  },
  scoreTxt: {
    fontWeight: '600',
    color: 'white',
    fontSize: 20,
    textAlign: 'center'
  },
  assessName: {
    width: '100%',
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    fontSize: 16,
    fontWeight: '600'
  },
  createdAt: {
    width: '100%',
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    fontSize: 14,
    color: 'gray',
    paddingTop: 5
  },
  complianceTxt: {
    fontSize: 12,
    color: '#303030',
    paddingBottom: 5
  },
  complianceValue: {
    fontSize: 14,
    fontWeight: '600',
    color: '#303030',
    paddingBottom: 5
  },
  legendContainer: {
    flexDirection: 'row',
    paddingVertical: 25,
    flexWrap: 'wrap',
    paddingHorizontal: 40
  },
  legend: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 150
  },
  legendColor: {
    width: 60,
    height: 25,
    borderRadius: 7,
    borderWidth: 3,
    borderColor: '#FFFEEB',
    borderStyle: 'double'
  },
  legendLabel: {
    color: '#2d2d2d',
    fontSize: 14,
    fontWeight: '500',
    paddingLeft: 10
  },
  summaryContainer: {
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'space-between'
  },
  titleBar: { 
    flexDirection: 'row',
    flex: 1,
  },
  titleContainer: {
    marginLeft: 15,
    wordBreak: 'break-word',
    marginRight: 15,
    flex: 1,
  }
}
