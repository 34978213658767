import { compose, createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { reducer as form } from 'redux-form'
import snackbar from './reducers'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default client => createStore(
  combineReducers({ form, snackbar }),
  undefined,
  composeEnhancers(applyMiddleware(thunkMiddleware.withExtraArgument(client)))
)
