import { graphql, Query, Mutation } from 'react-apollo'
import { currentClient } from '../../graphql/query/client'
import { setGoal } from '../../graphql/mutation/client'
import { client } from '../../graphql/query/client'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import { Assessment, FitnessCenter, Chat } from '@material-ui/icons'
import styles from '../styles'

@graphql(currentClient)
export default class Client extends Component {
  state = { open: false, goals: '' }
  render() {
    const { 
      data: { currentClient },
      history,
      match: { url },
      triggerDrawer,
    } = this.props
    const { first_name, last_name, email, goals, id, physical_therapy } = currentClient
    return (
      <Query
        query={client}
        variables={{ id }}
        fetchPolicy="network-only"
      >
        {({ data, error, loading }) => {
          if (loading) return <ActivityIndicator style={{ marginTop: 20 }} />
          if (error) return <Text>Something went wrong. Please try again.</Text>
          return (
            <View style={{ justifyContent: 'center', alignItems: 'center', padding: 20, textAlign: 'center' }}>
              <Avatar style={{width: 70, height: 70, fontSize: '2.1875rem' }}>
                {`${currentClient.first_name[0].toUpperCase()} ${currentClient.last_name[0].toUpperCase()}`}
              </Avatar>
              <Text style={styles.clientName}>{`${first_name} ${last_name}`}</Text>
              <Text style={styles.clientEmail}>{email}</Text>
              <View style={{ flexDirection: 'row', paddingTop: 15 }}>
                <TouchableOpacity
                  style={[styles.clientNavBtn, { backgroundColor: 'rgb(66, 135, 245)' }]}
                  onPress={() => {
                    history.push(`${url}`)
                    triggerDrawer();
                  }}
                >
                  <FitnessCenter style={{ fontSize: 40 }} htmlColor="white" />
                  <Text style={styles.navBtnTxt}>Workouts</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.clientNavBtn, { backgroundColor: 'rgb(245, 69, 69)' }]}
                  onPress={() => {
                    history.push(`${url}assessments`)
                    triggerDrawer();
                  }}
                >
                  <Assessment style={{ fontSize: 45 }} htmlColor="white" />
                  <Text style={styles.navBtnTxt}>Assessments</Text>
                </TouchableOpacity>
              </View>
              <View style={{ flexDirection: 'row' }}>
              <TouchableOpacity
                  style={[styles.clientNavBtn, { 
                    backgroundColor: 'rgb(110, 206, 0)',
                    width: 210,
                  }]}
                  onPress={() => {
                    history.push(`${url}messages`)
                    triggerDrawer();
                  }}
                >
                  <Chat style={{ fontSize: 45 }} htmlColor="white" />
                  <Text style={styles.navBtnTxt}>Messages</Text>
                </TouchableOpacity>
              </View>

                {physical_therapy
                  ? null
                  : <View style={styles.goals}>
                     {data.client && data.client.goals && <Text style={styles.goalTitle}>My Goals</Text>}
                     {data.client && data.client.goals && data.client.goals.map((goal, index) => {
                       return (
                         <Text key={goal.id} style={styles.goal}>
                          <span style={styles.goalId}>{index + 1}.</span>
                          <span style={styles.goalText}>{goal.goal || ''}</span>
                         </Text>
                       )
                     })}
                     <TouchableOpacity onPress={() => this.handleClickOpen()}>
                       <Button color="primary">Add Goal</Button>
                     </TouchableOpacity>
                     <Mutation
                       mutation={setGoal}
                       onCompleted={() => this.setState({ goals: '' })}
                     >
                      {(setGoal) => (
                        <Dialog
                          fullWidth
                          open={this.state.open}
                          onClose={this.handleClose}
                          >
                          <DialogTitle>{"Add a Goal"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              <TextField
                                fullWidth
                                label="Set a Goal"
                                onChange={this.handleChange}
                                multiline
                                value={this.state.goals}
                                rows="4"
                                margin="normal"
                                variant="outlined"
                                />
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                              Cancel
                            </Button>
                            <Button onClick={() => this.handleSubmit(setGoal)} color="primary">
                              Add
                            </Button>
                          </DialogActions>
                        </Dialog>
                      )}
                    </Mutation>
                  </View>
                }
            </View>
          )
        }}
      </Query>
    )
  }
  handleSubmit = mutation => {
    const { data: { currentClient } } = this.props
    mutation({
      variables: {
        user_id: currentClient.id,
        done: false,
        goal: this.state.goals
      },
      refetchQueries: () => [`client`]
    });
    this.handleClose();
  }

  handleChange = event => {
    this.setState({
      goals: event.target.value,
    })
  }
  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }
}
