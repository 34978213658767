import { WorkoutHeader, Exercises } from './workout'
import { sortExercises } from '../helpers/workout'

export default class extends Component {
  render() {
    const { trainee_uuid, date, exercises, prevDay, nextDay, selectDay } = this.props
    const sortedExercises = sortExercises(exercises)

    return (
      <View>
        <WorkoutHeader
          {...this.props}
          exercises={exercises}
          date={date}
          compliance={compliance(exercises)}
          prevDay={prevDay}
          nextDay={nextDay}
          selectDay={selectDay}
          trainee_uuid={trainee_uuid}
        />
        <View style={{flexDirection: 'row'}}>
          {sortedExercises && sortedExercises.length > 0
            ? <View style={{ flex: 1, borderWidth: 1, borderColor: '#f4f4f4' }}>
                <Exercises
                  {...this.props}
                  trainee_uuid={trainee_uuid}
                  exercises={sortedExercises}
                />
              </View>
            : <Text style={{ margin: 'auto', color: 'gray' }}>No Exercises Today</Text>
          }
        </View>
      </View>
    )
  }
}


const compliance = (exrs=[]) =>
  Math.round(filter(exrs, 'done_at').length / exrs.length * 100)
