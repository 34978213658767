import { Query } from 'react-apollo'
import { user_by_token } from '../../graphql/query/client'
import Form from './form'


export default class Signup extends Component {
  render() {
    const { token, onSignup } = this.props
    return (
      <Query
        query={user_by_token}
        variables={{ token }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          if (error) return <Text style={{textAlign: 'center'}}>Please try resetting your password, or if you're a new user, contact your provider for a fresh activation email.</Text>
          if (loading) return <ActivityIndicator />
          // if (data && data.client.active) this.reroute()
          return (
            <Form user={data.user_by_token} initialValues={{token}} onSignup={onSignup} />
          )
        }}
      </Query>
    )
  }
  reroute = () => this.props.history.push('/login')
}
