import React, { useState } from 'react'
import { currentClient } from '../graphql/query/client'
import { useQuery } from '@apollo/client'
import { Dimensions } from 'react-native-web'
import { graphql, ApolloConsumer, Query, Mutation } from 'react-apollo'
import { trimStart, trimEnd } from 'lodash'
import { isMobile } from 'react-device-detect'
import OmeBar from './home/omeBar'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Switch, Route, Redirect } from 'react-router-dom'
import Signup from './home/signup'
import Login from './home/login'
import Download from './download'
import Workout from './workoutContainer'
import { workoutObservations } from '../graphql/query/workout'
import Avatar from '@material-ui/core/Avatar'
import Paper from '@material-ui/core/Paper'
import Snackbar from './snackbar'
import Exercise from './workout/exercise/detail'
import Tutorial from './tutorial'
import Summary from './assessments/summary'
import Assessments from './assessments'
import Messages from './messages';

import styles from './styles'

@graphql(currentClient)
export default class extends Component {
  componentDidMount() {
    this.props.history.replace(this.getRedirect())
  }

  render() {
    const { data } = this.props
    return (
      <ApolloConsumer>
        {client => (
          <View style={{ flexDirection: 'row', height: '100%' }}>
            <OmeBar
              {...this.props}
              user={data && data.currentClient}
              logo={require("../../public/images/logo.png")}
              logout={() => this.logout(client)}
            />
            <View style={ styles.content }>
              <Switch>
                <Route exact path="/signup/:token" component={SignupContainer} />
                <Route path="/download" render={this.renderDownload} />
                <Route path="/tutorial" component={TutorialContainer} />
                <Route
                  exact
                  path="/login"
                  component={LoginContainer}
                />
                <PrivateRoute
                  {...this.props}
                  path="/"
                  component={HomeContainer}
                />
              </Switch>
              <Snackbar />
            </View>
          </View>
        )}
      </ApolloConsumer>
    )
  }

  logout = async (client) => {
    client.cache.reset()
    await AsyncStorage.clear()
    this.props.history.push('/login')
  }

  getRedirect = () => {
    const { pathname, search } = this.props.location
    return trimStart(search, '?r=') || trimEnd(pathname, '/')
  }

  renderDownload = props => (
    <View style={styles.unauthContainer}>
      <View style={isMobile ? styles.formContainer : styles.downloadContainer}>
        <Download
          {...props}
          title={"Welcome!"}
          subtitle={"Your optimumMe account is now ready. Download the app and enjoy now!"}
        />
      </View>
    </View>
  )
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        rest.data.currentClient
          ? <Component {...props} />
          : <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
      }
    />
  )
}

@graphql(currentClient)
class SignupContainer extends Component {
  goToHomePage = () => this.props.history.push('/')
  render() {
    const { data } = this.props
    if (data && data.currentClient) {
      this.goToHomePage();
      return null;
    }
    return (
      <View style={styles.unauthContainer}>
        <View style={isMobile ? styles.formContainer : styles.webformContainer}>
          <Signup {...this.props} token={this.props.match.params.token}
                  onSignup={() => this.props.history.push('/download')} />
        </View>
      </View>
    )
  }
}

@graphql(currentClient)
class LoginContainer extends Component {
  goToHomePage = () => this.props.history.push('/')
  render() {
    const { data } = this.props
    if (data && data.currentClient) {
      this.goToHomePage();
      return null;
    }
    return (
      <View style={{height, flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <View style={{width: 350, maxWidth: '100%'}}>
          <Login onSignin={this.goToHomePage}/>
        </View>
      </View>
    )
  }
}

class TutorialContainer extends Component {
  render() {
    return (
      <View style={{ padding: 15 }}>
        <Tutorial />
      </View>
    )
  }
}

@graphql(currentClient)
class HomeContainer extends Component {
  componentDidUpdate(){
    if(this.main) this.main.scrollTo(0,0);
  }
  render() {
    const { data: { currentClient }, match: { path } } = this.props
    return (
      <Paper style={styles.contentContainer} component='main' ref={ref => {this.main = ref}}>
        <Switch>
          <Route
            exact
            path="/"
            render={props =>
              <WorkoutContainer
                client={currentClient}
                { ...props }
              />
            }
          />
          <Route
            exact
            path={`${path}exercise/:exerciseId`}
            render={props => this.renderExercise(props)}
          />
          <Route
            exact
            path={`${path}assessments`}
            render={props => <Assessments {...props} />}
          />
          <Route
            exact
            path={`${path}summary/:assessId`}
            render={props => <Summary {...props} />}
          />
          <Route
            exact
            path={`${path}assessment/:assessId`}
            render={props => this.renderDownloadAppToFinishAssessment(props)}
          />
          <Route
            exact
            path={`${path}messages`}
            render={props => <Messages {...props} />}
          />
        </Switch>
      </Paper>
    )
  }
  renderExercise = props => {
    return props.history.location.state
      ? <Exercise
          {...props}
          exr={props.history.location.state.exr}
        />
      : props.history.push('/')
    }

  renderDownloadAppToFinishAssessment = props => (
    <View style={styles.unauthContainer}>
      <View style={isMobile ? styles.formContainer : styles.downloadContainer}>
        <Download
          {...props}
          title={""}
          subtitle={"Please download the optimumMe mobile app to edit your outcome measure."}
        />
      </View>
    </View>
  )
}

const WorkoutContainer = props => {
  const [startDate, setStartDate] = useState(moment(window.location.hash || undefined).format("YYYY-MM-DD"))
  const { client } = props
  const { loading, error, data, refetch } = useQuery(workoutObservations, {
    variables: { trainee_uuid: client.uuid, start_date: startDate },
    fetchPolicy: 'no-cache'
  })
  const selectDay = date => {
    const newDate = date.format('YYYY-MM-DD')
    setStartDate(newDate)
    window.location.hash = newDate
  }
  const nextDay = () => {
    const newDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD')
    setStartDate(newDate)
    window.location.hash = newDate
  }
  const prevDay = () => {
    const newDate = moment(startDate).subtract(1, 'days').format('YYYY-MM-DD')
    setStartDate(newDate)
    window.location.hash = newDate
  }

  return (
    <React.Fragment>
      <Text style={{ fontSize: 20, fontWeight: '600'}}>My Workouts</Text>
      {loading ? <ActivityIndicator style={{ marginTop: 20 }} /> : null}
      {error ? <Text>Something went wrong. Please try again.</Text> : null}
      {
        !loading && !error ? (
          <Workout
            {...props}
            trainee_uuid={client.uuid}
            date={startDate}
            prevDay={() => prevDay()}
            nextDay={() => nextDay()}
            selectDay={(date) => selectDay(date)}
            exercises={data && data.workout_observations}
            refetch={refetch}
          />
        ) : null
      }
    </React.Fragment>
  )
}

const { height } = Dimensions.get('window')
