import { Component, useState } from 'react'
import { useQuery } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'
import MomentUtils from '@date-io/moment'
import { Button } from 'react-native-web'
import { SectionList } from '../common/SectionList'
import Header from '../common/Header'
import Exercise, { requiredObFields } from './exercise'
import LabeledProgressBar from '../common/LabeledProgressBar'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { workoutSchedule } from '../../graphql/query/workout'
import { uniqBy } from 'lodash'
import style from './style'
import { workout_exercises } from '../../graphql/mutation/workout'

const sessions = ['Physical Therapy Exercises', 'Morning', 'Afternoon', 'Evening']

export const WorkoutHeader = ({trainee_uuid, date, exercises, prevDay, nextDay, selectDay, compliance, refetch}) => {
  const [startDate, setStartDate] = useState(date)
  const { loading, data, error } = useQuery(workoutSchedule, {
    variables: {
      trainee_uuid,
      start_date: moment(startDate).startOf('month'),
      end_date: moment(startDate).endOf('month')
    },
    fetchPolicy: 'no-cache'
  })
  const schedule = data && uniqBy(data.workout_observations, exr => exr.scheduled_date)
  const woSchedule = schedule && schedule.map(exr => moment(exr.scheduled_date).date())

  return (
    <Header headerTitle={
      <View>
        <View style={style.workoutDate}>
          <TouchableOpacity style={{ paddingHorizontal: 20 }} onPress={() => prevDay()}>
            <ChevronLeft size={25} />
          </TouchableOpacity>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              value={date}
              autoOk={true}
              onChange={e => {
                selectDay(moment(e));
                setStartDate(moment(e).format('YYYY-MM-DD'))
              }}
              onMonthChange={(e) => {
                setStartDate(moment(e).format('YYYY-MM-DD'))
              }}
              renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                const isSelected = isInCurrentMonth && woSchedule && woSchedule.length > 0 && woSchedule.includes(Number(dayComponent.props.children))
                return isSelected
                  ? <Badge variant="dot" color="primary">{dayComponent}</Badge>
                  : dayComponent
              }}
              inputProps={{style:style.datePicker}}
            />
          </MuiPickersUtilsProvider>
          <TouchableOpacity style={{ paddingHorizontal: 20 }} onPress={() => nextDay()}>
            <ChevronRight size={25} />
          </TouchableOpacity>
        </View>
        <View style={{ paddingVertical: 20 }}>
          {exercises
           ? <ProgressBar compliance={compliance}/>
           : <Text>No exercises today</Text>
          }
         </View>
         <CompleteAllExercisesButton refetch={refetch} exercises={exercises} date={date}/>
      </View>
    }
    />
  )
}


export const toggleExrs =
  graphql(
    workout_exercises,
    {
      props: ({mutate}) => ({toggleExrs: exrs => mutate({
        variables: {
          observations: exrs.map(exr => (
            exr.original_daily_index ? {
              ...pick(exr, requiredObFields),
              daily_index: exr.original_daily_index
            } : pick(exr, requiredObFields)
          ))
        },
      })})
    }
  )

@toggleExrs
class CompleteAllExercisesButton extends Component {
  state = { loading: false }
  completeAllExercises = () => {
    const { exercises, refetch, toggleExrs } = this.props;
    const today = moment().format('YYYY-MM-DD');
    const newExercises = (exercises.filter(exr => exr.done_at).length === exercises.length)
      ? exercises.filter(exr => exr.done_at).map(exr => ({...exr, done_at: null}))
      : exercises.filter(exr => !exr.done_at).map(exr => ({...exr,done_at: today}));
    this.setState({ loading: true });
    toggleExrs(newExercises)
      .then(refetch)
      .then(() => this.setState({ loading: false }));
  }
  render(){
    const { exercises, date } = this.props
    const isNonFutureDate = moment(date).isSameOrBefore(moment(), 'day');
    return exercises && exercises.length > 1 && isNonFutureDate ? (
      <View style={{ alignItems: 'center', position: 'relative' }}>
        <Button
          title="Complete All Exercises"
          onPress={this.completeAllExercises}
          disabled={this.state.loading}
        />
        { this.state.loading ? <ActivityIndicator style={{position: 'absolute', height: '100%'}}/> : null }
      </View>
    ) : null

  }
}


export const Exercises = props => {
  const { exercises=[] } = props
  const splitExercises = map(exercises, exr => ({
    ...exr,
    daily_index: exr.workout_id ? 0 : exr.daily_index,
    original_daily_index: exr.daily_index
  }))
  return (
    <SectionList
      data={exercises}
      renderItem={
        ({item}) =>
        <Exercise {...props} key={uniqueId()} exr={item} />
      }
      renderSectionHeader={
        ({section: {title, data}}) =>
          <SessionHeader key={uniqueId()} title={title} exrs={data} color={exerciseHeaderColor(title)} />
      }
      sections={
        map(groupBy(splitExercises, 'daily_index'),
          (exrs, i) => ({title: sessions[i], data: exrs}))}
      keyExtractor={item => item.id}
      stickySectionHeadersEnabled
    />
  )
}

export const SessionHeader = ({title, exrs, color}) =>
  <View style={[style.sessionHeader, { backgroundColor: color }]}>
    <Text style={style.sessionTitle}>{`${title} Exercises`}</Text>
    <Text style={style.sessionTotal}>
      {filter(exrs, 'done_at').length}
      /
      {exrs.length}
    </Text>
  </View>

const ProgressBar = ({compliance}) =>
  <LabeledProgressBar
    style={style.progressBar}
    leftelement={<Text style={style.progressLabelLeft}>Workout Progress</Text>}
    rightelement={<Text style={style.progressLabelRight}>{`${compliance || 0}%`}</Text>}
    value={compliance || 0}
  />

const exerciseHeaderColor = title => {
  if (title === 'Morning') {
    return '#B9C586'
  } else if (title === 'Afternoon') {
    return '#D59F84'
  } else if (title === 'Evening') {
    return '#B880AD'
  } else {
    return 'rgb(247, 57, 107)'
  }
}
