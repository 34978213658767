import { createTheme } from '@material-ui/core/styles'
import cyan from '@material-ui/core/colors/cyan'
import pink from '@material-ui/core/colors/pink'
import blueGrey from '@material-ui/core/colors/blueGrey'

export const theme = createTheme({
  palette: {
    primary: cyan,
    secondary: pink,
  },
  overrides: {
    MuiToolbar: {
      root: {
        background: 'white'
      }
    },
    MuiTab: {
      label: {
        fontSize: '0.7125rem',
        color: 'white'
      }
    },
    MuiFormControlLabel: {
      root: {
        height: 30,
      },
      label: {
        paddingLeft: 15
      }
    },
    MuiSwitch: {
      default: {
        color: 'gainsboro'
      }
    },
    MuiSlider: {
      mark: {
        height: 10
      }
    },
    MuiCssBaseline: {
      '@global': {
        'html, body, #app': {
          height: '100%',
          overflowY: 'hidden',
        },
        '*': {
          scrollbarColor: '#3333 white',
          scrollbarWidth: 'thin',
        },
        '*::-webkit-scrollbar': {
          width: '7px',
          height: '7px'
        },
        '*::-webkit-scrollbar-track': {
          background: '#DDD',
          '-webkit-box-shadow': 'inset 0 0 6px white',
        },
        '*::-webkit-scrollbar-thumb': {
          background: '#666',
          borderRadius: '5px',
          '-webkit-box-shadow': 'inset 0 0 6px #3333',
          border: '1px solid white'
        }
      }
    }
  },
})
