
const excellent = '#6ece00'
const good = '#b1d639'
const fair = '#FFCC01'
const poor = '#FF9500'
const very_poor = '#FF3A30'

const colors = [very_poor, poor, fair, good, excellent]

export const setPosition = msrs =>
  msrs && msrs.map(msr =>
    msr['position'] === null
      ? {...msr, position: 0}
      : msr
 )

export const scoreInfo = (percent, assessType) => {
  const maxScore = assessType.best_score || 100
  return getScoreInfo(percent, assessType.risk || assessType.name === 'Cardio' ? heartLegend : startLegend, maxScore)
}

const getScoreInfo = (score, legend, maxScore) => {
  if (score >= maxScore * .80) return legend[0]
  else if ((maxScore * .79 >= score) && (score >= maxScore * .60)) return legend[1]
  else if ((maxScore * .59 >= score) && (score >= maxScore * .40)) return legend[2]
  else if ((maxScore * .39 >= score) && (score >= maxScore * .20)) return legend[3]
  else if (maxScore * .19 >= score) return legend[4]
}


const makeLegend = (colors, labels) =>
  zip(colors, labels)
    .map(([color, label]) => ({color, label}))

export const heartLegend =
  makeLegend(colors, ['Very High Risk', 'High Risk', 'Moderate Risk', 'Low Risk', 'Very Low Risk'])

export const startLegend =
  makeLegend(colors.reverse(), ['Excellent', 'Good', 'Fair', 'Poor', 'Very Poor'])
