import React from 'react'
import { Provider } from 'react-redux'
import { ApolloProvider as ApolloClientProvider } from '@apollo/client'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import { BrowserRouter, Route } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './themes/ome'
import { store } from './helpers/store'
import { clientPromise } from './helpers/client'
import App from './components'
import createStore from './config/store'
import { CssBaseline } from '@material-ui/core'

export class Root extends Component {
  state = {client: null}

  async componentDidMount() {
    const client = await clientPromise
    this.setState({client})
  }
  
  render() {
    const { client } = this.state
    return client && (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ApolloProvider client={client}>
          <ApolloHooksProvider client={client}>
            <ApolloClientProvider client={client}>
              <Provider store={createStore(client)}>
                <BrowserRouter>
                  <Route path="/" component={App} />
                </BrowserRouter>
              </Provider>
            </ApolloClientProvider>
          </ApolloHooksProvider>
        </ApolloProvider>
      </MuiThemeProvider>
    )
  }
}
