export const validatePassword = (value) => {
  if (value == undefined) {
    return 'Required'
  }  else if (value && /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#%^*$&()\\-`.+,\"]{8,}$/.test(value)) {
    return ''
  } else {
    return 'Must contain a number and be at least 8 characters long.'
  }
}

export const validateConfirmPassword = (_, allValues) => {
  return allValues.password === allValues.password_confirmation
       ? undefined
       : 'Passwords must match'
}
