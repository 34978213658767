import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'babel-polyfill';
import { render } from 'react-native';
import { Root } from './root';
import React from 'react';

try {
  if (module.hot !== undefined) module.hot.accept();
} catch(e) {
  console.log('we are getting an error:', e);
};

render(<Root />, document.getElementById('app'));
