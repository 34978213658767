import React, { useState, useEffect } from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { workout_exercise } from '../../../graphql/mutation/workout'
import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'
import { Image, TouchableOpacity } from 'react-native-web'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Exercise from './detail'
import { reject } from 'lodash'
import { style, cardStyle } from './style'

export const mutateExr = (mutate, exr) =>
  mutate({
    variables: {
      observation: exr.original_daily_index ? {...pick(exr, requiredObFields), daily_index: exr.original_daily_index } : pick(exr, requiredObFields)
    },
    optimisticResponse: {workout_observation: exr}
  })

export const toggleExr =
  graphql(
    workout_exercise,
    {props: ({mutate}) => (
      {toggleExr: exr =>
        mutateExr(mutate, toggleComplete(exr))})})

@toggleExr
export default class extends Component {
  render() {
    const { match: { url, path }, exr, toggleExr, history } = this.props
    return (
      <TouchableOpacity
        style={{ flex: 1, cursor: 'pointer', borderBottomWidth: .5, borderBottomColor: 'gainsboro', padding: 20 }}
        onPress={() => history.push({
          pathname: `${url}exercise/${exr.id}`,
          state: { exr }
        })}
      >
        <Text style={style.exrsTitle} numberOfLines={1}>
          {exr.exercise.name}
        </Text>
        <Details refetch={this.props.refetch} exr={exr} toggleExr={toggleExr}/>
      </TouchableOpacity>
    )
  }
}

const Details = ({exr, toggleExr, refetch}) => {
  const {exercise, done_at, parameters, done_count, qty} = exr
  const [isLoading, setIsLoading] = useState(false);

  return (
    <View style={style.exrsDetails}>
      <View style={{ flexDirection: 'row', flex: 5 }}>
        {exr.qty - exr.done_count > 1
          ? <Badge badgeContent={`x${qty - done_count}`} color="secondary" >
              <WorkoutImage style={style.exrsImage} uri={exercise.image_urls[0]}/>
            </Badge>
          : <WorkoutImage style={style.exrsImage} uri={exercise.image_urls[0]}/>
        }
        <Parameters style={style.params} parameters={parameters}/>
      </View>
      <FormControlLabel
        style={{ flex: 1 }}
        control={
          <View style={{ flex: 1, position: 'relative', alignItems: 'center' }}>
            <Switch
              disabled={isLoading}
              checked={!!done_at}
              onChange={() => {
                setIsLoading(true);
                toggleExr(exr)
                  .then(refetch)
                  .then(() => setIsLoading(false));
              }}
              onClick={(e) =>{
                e.stopPropagation();
                e.preventDefault();
              }}
              value="exr"
              color="primary"
            />
            { isLoading ? <ActivityIndicator style={{position: 'absolute', height: '100%'}}/> : null }
          </View>
        }
      />
    </View>
  )
}

export const WorkoutImage = ({uri, style: _style}) =>
  <View style={_style}>
    {uri
     ? <Image style={style.exerciseImage} source={{uri}}/>
     : <Avatar size={50}>N/A</Avatar>
    }
  </View>


export const Parameters = ({parameters, style}) =>
  <View style={style}>
    {reject(parameters, ['label', 'free text'])
      .map((param, i) =>
        <Parameter key={i} parameter={param}/>)}
  </View>

export const Parameter = ({parameter: {label, value}}) =>
  <View style={style.param}>
    <Text style={style.paramValue}>{value}</Text>
    <Text style={style.paramLabel}>{label}</Text>
  </View>

// TODO: use lodash update, use proper done_at date
const toggleComplete = exr => (
  {...exr, done_at: (exr.done_at ? null : moment().format('YYYY-MM-DD'))})

export const requiredObFields = [
  'tag', 'workout_tag', 'workout_id', 'id', 'workout_exercise_id',
  'daily_index', 'period_id', 'scheduled_date', 'done_at',
  'note', 'difficulty_index', 'pain_index'
]
