import React from 'react'
import { View, Text } from 'react-native-web'
import { LinearProgress as _ProgressBar } from '@material-ui/core'
import { style } from './style'

export default class LabeledProgressBar extends React.Component {
  render() {
    const {leftelement, rightelement,
           color, height=8,
           value,
           style: _style,
          ...props} = this.props
    return (
      <View style={_style}>
        <View style={style.progressLabels}>
          {leftelement}
          {rightelement}
        </View>
        <View>
          <ProgressBar
            width={null}
            color={color || 'primary'}
            height={height}
            progress={value / 100}
            {...props}
          />
        </View>
      </View>
    )
  }
}

const ProgressBar = ({progress, color, height, ...props}) =>
  <_ProgressBar
    variant="determinate"
    color={color || '#A1C873'}
    value={progress * 100}
    style={{height: height || 9}}
    {...props}
  />
