export const style = {
  card: {
    padding: 10,
  },
  headerContainer: {
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
    backgroundColor: 'white',
  },
  headerLeft: {
    alignItems: 'center',
    marginRight: 15,
  },
  headerTitle: {
    flex: 1,
  },
  headerRight: {
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
  },
  subtitle: {
    fontSize: 12,
  },
  text: {
    fontSize: 12,
    color: 'gray',
  },
  activationText: {
    color: 'gray',
    fontSize: 12,
    textDecorationLine: 'underline',
  },
  padding: {
    padding: 5,
  },
  toolbarContainer: {
    flexDirection: 'row-reverse',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
    flexWrap: 'wrap'
  },
  toolbarBtn: {
    marginLeft: 15,
  },
  labelContainer: {
    backgroundColor: '#2C98F0',
    padding: 3,
    borderRadius: 5,
  },
  labelText: {
    color: 'white',
    textAlign: 'center',
    fontStyle: 'italic',
    paddingLeft: 7,
    paddingRight: 7,
  },
  radioBtns: {
    paddingTop: 10,
  },
  radioBtnContainer: {
    paddingLeft: 10,
  },
  radioTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  progressLabels: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5,
  },
  headerAssessmentTitle: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
    paddingVertical: 7,
    paddingHorizontal: 15,
    borderRadius: 3,
  },
  disabled: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.4)',
    paddingVertical: 7,
    paddingHorizontal: 15,
    borderRadius: 3,
  },
  btnText: {
    color: 'white',
    fontSize: 14,
    fontWeight: '500',
  },
  circleLabel: {
    position: 'absolute',
    margin: 0,
    top: '38%',
    width: '100%',
    textAlign: 'center',
  },
  valueCircle: {
    position: 'absolute',
    zIndex: 10,
  },
  circles: {
    position: 'absolute',
  },
  backgroundCircle: {
    position: 'absolute',
    zIndex: 5,
  },
  disabledContainer: {
    opacity: 0.3,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  bubbleText: {
    color: 'rgba(0,0,0,0.7)',
    fontSize: 14,
    fontWeight: '600',
    position: 'absolute',
    textAlign: 'center',
    zIndex: 10,
  },
  bubbleTextToggled: {
    color: 'white',
    zIndex: 11
  },
  toggleComponent: {
    position: 'absolute',
    zIndex: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  check: {
    position: 'absolute',
    zIndex: 15,
  },
  sectionHeader: {
    paddingHorizontal: 15,
    paddingVertical: 20,
    flexDirection: 'row',
    backgroundColor: 'white',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(112, 112, 112, 0.16)',
  },
  sectionTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  listItem: {
    flex: 1,
    paddingVertical: 10,
    borderBottomWidth: 0.5,
    borderBottomColor: 'gainsboro',
    flexDirection: 'row',
    backgroundColor: 'white',
    justifyContent: 'space-between'
  },
  contentContainer: {
    flexDirection: 'row',
    flex: 1
  },
  leftElement: {
    flex: 1.5,
    alignItems: 'center'
  },
  centerElement: {
    flex: 4,
    justifyContent: 'center'
  },
  listTxtContainer: {
    justifyContent: 'center'
  },
  listTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: 'rgb(109,109,110)'
  },
  listSubtitle: {
    color: 'gray',
    fontSize: 14,
    paddingTop: 10
  }
}
