import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Mutation } from "react-apollo";
import { TouchableOpacity } from "react-native-web";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import { Paper, Button, Divider, useMediaQuery, TextareaAutosize } from "@material-ui/core";
import { currentClient } from "../../graphql/query/client";
import { threads, thread } from "../../graphql/query/messages";
import { sendMessage } from "../../graphql/mutation/client";

const fadeInComponent = (component) => {
  if (!component) return;
  component.animate([{ opacity: 0 }, { opacity: 1 }], {
    duration: 500,
  });
};

export default ({ history }) => {
  const { data: userData } = useQuery(currentClient);
  const userUuid = useMemo(() => userData?.currentClient?.uuid, [userData]);
  const {
    loading: isThreadsLoading,
    error: threadsError,
    data: threadsDataFromQuery,
  } = useQuery(threads, {
    variables: { uuid: userUuid },
    fetchPolicy: "cache-and-network",
  });
  const threadsData = useMemo(() => {
    const { threads } = threadsDataFromQuery || [];
    if (!threads) return null;
    threads.forEach((thread) => {
      const latestMessage = thread.latest_message || {};
      latestMessage.timeMoment = moment(latestMessage.time || 0);
      latestMessage.timestamp = +latestMessage.timeMoment;
      latestMessage.timeDisplay = latestMessage.timeMoment.format("LLL");
    });
    return threads;
  }, [threadsDataFromQuery]);

  const [isSending, setIsSending] = useState(false);
  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const redirectThreadId = useMemo(() => history?.location?.state?.threadId || null, [history]);
  useEffect(() => {
    if (redirectThreadId) setSelectedThreadId(redirectThreadId);
  }, [redirectThreadId]);
  const [
    loadThread,
    { loading: isThreadLoading, error: threadError, data: threadDataFromQuery },
  ] = useLazyQuery(thread, {
    variables: { user: userUuid, thread_id: selectedThreadId },
    fetchPolicy: "cache-and-network",
  });
  const reloadCurrentThread = useCallback(() => {
    if (!selectedThreadId) return;
    loadThread({
      variables: { thread_id: selectedThreadId },
    });
  }, [loadThread, selectedThreadId]);
  useEffect(() => {
    reloadCurrentThread();
  }, [reloadCurrentThread]);
  const threadData = useMemo(() => {
    const thread = threadDataFromQuery?.threads?.[0] || null
    if (!selectedThreadId || !thread || thread.id !== selectedThreadId)
      return null;
    (thread.messages || []).forEach((message) => {
      message.timeMoment = moment(message.time || 0);
      message.timestamp = +message.timeMoment;
      message.timeDisplay = message.timeMoment.format("LLL");
    });
    (thread.messages || []).sort((a, b) => a.timestamp - b.timestamp);
    return thread;
  }, [threadDataFromQuery, selectedThreadId]);

  const inputBoxRef = useRef(null);
  const messagesContainerRef = useRef(null);
  useEffect(() => {
    if (!threadData) return;
    setTimeout(() => {
      inputBoxRef.current.focus();
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
      });
    }, 0);
  }, [threadData]);
  useEffect(() => {
    if (!selectedThreadId) return;
    const resizeListener = (e) => {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
      });
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [selectedThreadId]);

  const shouldUseDesktopLayout = !!useMediaQuery((theme) =>
    theme.breakpoints.up("md")
  );
  const shouldShowThreadList = shouldUseDesktopLayout || !selectedThreadId;
  const shouldShowMessageList = selectedThreadId;

  const renderThread = useCallback((thread) => {
    const { context, id } = thread;
    const latestMessage = thread.latest_message || {};
    const { content, timeDisplay } = latestMessage;
    const isSelected = !selectedThreadId || (selectedThreadId === id);
    return (
      <TouchableOpacity
        key={id}
        onPress={() => {
          setSelectedThreadId(id);
          fadeInComponent(messagesContainerRef && messagesContainerRef.current);
        }}
      >
        <Paper
          style={isSelected ? styles.threadSelectedContainer : styles.threadContainer}
          elevation={!selectedThreadId || !isSelected ? 1 : 3}
          ref={(selectedThreadId === id) ? ref => ref && ref.scrollIntoViewIfNeeded() : null}
        >
          <Text style={styles.messageTitleBrief}>{context || ""}</Text>
          <Text style={styles.messageTextBrief}>{content || ""}</Text>
          <Text style={styles.messageTime}>{timeDisplay || ""}</Text>
          {isSelected ? <ChevronRight style={styles.messageExpandIcon} /> : null}
        </Paper>
      </TouchableOpacity>
    );
  }, [selectedThreadId]);

  const renderMessage = useCallback(
    (message) => {
      const {
        id,
        content,
        timeDisplay,
        user,
      } = message;
      const senderUuid = user?.uuid;
      const [
        paperStyle,
        messageTextStyle,
        messageTimeStyle,
      ] = (senderUuid === userUuid) ? [
        styles.messageContainerOurSide,
        styles.messageTextOurSide,
        styles.messageTimeOurSide,
      ] : [
        styles.messageContainerOtherSide,
        styles.messageTextOtherSide,
        styles.messageTimeOtherSide,
      ]
      return (
        <Paper
          key={id}
          style={paperStyle}
        >
          <Text style={messageTextStyle}>{content}</Text>
          <Text style={messageTimeStyle}>{timeDisplay}</Text>
        </Paper>
      );
    },
    [userUuid]
  );

  const submitMessage = useCallback(
    (event, sendMessage) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      const content = inputBoxRef.current.value;
      if (!content || isSending || !threadData) return;
      setIsSending(true);
      sendMessage({
        variables: {
          sender: userUuid,
          thread_id: threadData.id,
          receivers: [],
          content,
          messageable_id: (threadData || {}).messageable_id || null,
          messageable_type: (threadData || {}).messageable_type || null,
        },
      });
    },
    [isSending, setIsSending, threadData, userUuid]
  );

  if (threadsError || threadError)
    return <Text>Something went wrong, please try again.</Text>;
  return (
    <Mutation
      mutation={sendMessage}
      onCompleted={() => {
        setIsSending(false);
        reloadCurrentThread();
        inputBoxRef.current.value = "";
        inputBoxRef.current.focus();
      }}
    >
      {(sendMessage) => (
        <View style={styles.page}>
          <TouchableOpacity
            style={styles.title}
            onPress={() => setSelectedThreadId(null)}
          >
            <ChevronLeft
              style={
                selectedThreadId ? styles.backButton : styles.backButtonShrinked
              }
            />
            <Text style={styles.titleText}>Messages</Text>
            {threadData?.context ? (
              <Text style={styles.subtitleText}>
                {threadData?.context || ""}
              </Text>
            ) : null}
          </TouchableOpacity>
          <Divider />
          <View style={styles.threadsMessagesContainer}>
            <View
              style={
                shouldShowThreadList ? styles.threadsContainer : styles.hidden
              }
            >
              {isThreadsLoading && !threadsDataFromQuery ? (
                <ActivityIndicator size="large" style={{ padding: 20 }} />
              ) : null}
              {(threadsData || []).map((thread, index) =>
                renderThread(thread, index)
              )}
            </View>
            <View
              style={
                shouldShowMessageList
                  ? styles.messagesInputBoxContainer
                  : styles.hidden
              }
            >
              <View style={styles.messagesContainer} ref={messagesContainerRef}>
                {isThreadLoading && !threadData ? (
                  <ActivityIndicator size="large" style={{ padding: 20 }} />
                ) : null}
                <div style={{ flex: 1 }} />
                {(threadData?.messages || []).map((message) =>
                  renderMessage(message)
                )}
              </View>
              <form onSubmit={(e) => submitMessage(e, sendMessage)}>
                <View
                  style={
                    selectedThreadId
                      ? styles.inputBoxContainer
                      : styles.inputBoxContainerHidden
                  }
                >
                  <TextareaAutosize
                    style={isSending ? styles.inputBoxSending : styles.inputBox}
                    ref={inputBoxRef}
                  />
                  <Button
                    color="primary"
                    type="submit"
                    style={styles.sendMessageButton}
                  >
                    {isSending ? <ActivityIndicator color="white" /> : "Send"}
                  </Button>
                </View>
              </form>
            </View>
          </View>
        </View>
      )}
    </Mutation>
  );
};
