import { isMobile } from 'react-device-detect'

export default {
  container: {
    borderWidth: 0,
    borderRadius: 7,
    shadowColor: 'black',
    shadowRadius: 7,
    shadowOpacity: 0.4,
    shadowOffset: { width: 0, height: 3},
    elevation: 1,
    backgroundColor: 'white',
    padding: 25
  },
  title: {
    fontSize: 22,
    fontWeight: '600',
    textAlign: 'center',
    padding: 10
  },
  subtitle: {
    fontSize: 16,
    textAlign: 'center',
    padding: 10
  },
  webLinkTxt: {
    padding: 10,
    textAlign: 'center',
    fontSize: 13,
    color: '#007AFF'
  },
  btnContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 10,
    flexWrap: 'wrap'
  },
  btn: {
    margin: 10,
    alignSelf: 'center'
  },
  btnImg: {
    height: 50,
    width: 175
  },
  downloadImg: {
    flex: 1,
    height: isMobile ? 250 : 400,
    width: null,
    resizeMode: 'contain'
  }
}
