export const style = {
  exrsCard: {
    borderBottomWidth: 1,
    borderBottomColor: '#efefef',
    padding: 10,
  },
  detailContainer: {
    backgroundColor: 'white',
    flex: 2
  },
  exrsHeader: {
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
  },
  exrsTitle: {
    fontSize: 16,
    paddingLeft: 15,
    fontWeight: '600',
    flexShrink: 0,
    height: 20,
    lineHeight: '20px',
  },
  rateTitle: {
    fontSize: 20,
    fontWeight: '600',
    paddingTop: 15,
    paddingBottom: 15,
  },
  exrsDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 10,
  },
  exrsImage: {
    marginHorizontal: 20,
  },
  params: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  detailParams: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 10,
  },
  param: {
    alignItems: 'center',
    padding: 10,
  },
  paramValue: {
    fontSize: 16,
  },
  paramLabel: {
    color: 'gray',
  },
  exerciseImage: {
    height: 50, width: 50, borderRadius: 50},
  selectedExr: {
    backgroundColor: '#CBE6E6',
  },
  sliderContainer: {
    marginTop: 10,
    marginBottom: 10
  },
  sliderTitle: {
    fontSize: 14,
    fontWeight: '500',
  },
  slider: {
    height: 50,
    marginLeft: 5,
    marginRight: 5,
  },
  sliderTxts: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  txt: {
    color: 'gray',
  },
  noteContainer: {
    marginTop: 10,
  },
  textInput: {
    borderWidth: 1,
    borderColor: 'gainsboro',
    borderRadius: 5,
    padding: 5,
    height: 100,
  },
  noteBtn: {
    marginTop: 10,
    marginBottom: 10,
  },
  padding: {
    padding: 10
  },
  mediaContent: {
    height: 400,
    width: '100%'
  },
  mediaText: {
    position: 'absolute',
    padding: 10,
    backgroundColor: 'rgba(0,0,0,0.7)'
  },
  rateContainer: {
    marginVertical: 15,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

export const cardStyle = (isSelected) =>
  isSelected ? [style.selectedExr, style.exrsCard] : style.exrsCard
