
export const style = {
  container: {
    backgroundColor: 'white'
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 15
  },
  userInfo: {
    flexDirection: 'row'
  },
  userDetails: {
    marginLeft: 15,
    marginTop: 5
  },
  actionContainer: {
    width: 200, padding: 10
  },
  assessName: {
    fontSize: 18
  },
  userName: {
    fontSize: 15
  },
  gray: {
    color: 'gray'
  },
  padding: {
    paddingVertical: 5,
    paddingHorizontal: 20
  },
  center: {
    textAlign: 'center'
  },
  measureTitle: {
    fontSize: 18,
    marginTop: 10,
    marginLeft: 20,
    marginBottom: 10,
    paddingLeft: 10,
  },
  msrText: {
    fontSize: 16,
    paddingRight: 40,
    paddingTop: 20,
    paddingBottom: 10
  },
  msrDetails: {
    marginLeft: '8%'
  },
  msrName: {
    fontWeight: '500',
    fontSize: 16,
    marginLeft: 10,
  },
  msrDescription: {
    flexWrap: 'wrap',
    flex: 1,
    fontWeight: '600'
  },
  msrCard: {
    padding: 15,
  },
  msrBorder: {
    borderBottomWidth: 1,
    borderBottomColor: 'gainsboro'
  },
  msrContainer: {
    alignItems: 'center',
    flexDirection: 'row'
  },
  msrmnt: {
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: 'gainsboro'
  },
  lastMsrmnt: {
    padding: 15
  },
  msrmntDetails: {
    fontWeight: '500',
    paddingTop: 5
  },
  headerToggle: {
    alignItems: 'center',
    backgroundColor: 'white',
    zIndex: 10,
  },
  headerTitle: {
    fontSize: 16,
    fontWeight: '500',
    paddingTop: 10
  },
  headerDate: {
    fontSize: 14,
    color: 'gray',
    padding: 5
  },
  description: {
    fontSize: 14,
    backgroundColor: 'white',
    color: 'rgb(108, 111, 113)',
    padding: 20,
  },
  measures: {
    backgroundColor: 'white',
    borderTopWidth: 1,
    borderTopColor: 'gainsboro'
  },
  mobileLegendContainer: {
    flexDirection: 'row',
    flex: 1,
    backgroundColor: 'white',
    paddingTop: 20,
    paddingHorizontal: 20,
  },
  firstLegend: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderColor:'white',
    borderWidth: 0.5,
  },
  lastLegend: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  mobileLegend: {
    height: 8,
    borderColor: 'white',
    borderWidth: 0.4,
  },
  mobileLegendText: {
    fontWeight: '500',
    paddingTop: 10,
    fontSize: 10,
    flexWrap: 'wrap',
    flex: 1
  },
  scoreContainer: {
    width: 45,
    height: 45,
    backgroundColor: 'gainsboro',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10
  },
  scoreTxt: {
    fontWeight: '600',
    color: 'white',
    fontSize: 16,
    textAlign: 'center'
  },
}
