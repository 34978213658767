import { style } from './style'

export default class Detail extends Component {
  render() {
    const {
      ob: multiOb,
      msr: {msrs, description, id, rationale},
      scoreOb, color='gray',
      assessment_type
    } = this.props
    const ob = scoreOb(id)
    const recommendation = ob && ob.recommendation ? ob.recommendation : ''
    return (
      <View>
        {msrs.map((msr, index, msrs) => {
          const { id, name, description, display } = msr
          const ob = scoreOb(id)
          return (
            <View key={index}>
              {(ob && !display) &&
                <View
                  style={
                    msrs.length - 1 == index
                      ? style.lastMsrmnt
                      : style.msrmnt
                  }
                >
                  <Text numberOfLines={5} style={style.msrDescription}>
                    {description || name}
                  </Text>
                  <View>
                    <Text style={[style.msrText, {color: color}]}>{obValue(msr, ob.value)}</Text>
                    {assessment_type.name !== 'Cardio' && this.showCutoffs(msr, ob.value)}

                    {/* <HTML html={ob.recommendation} /> */}
                    <pre>{ob.recommendation}</pre>
                  </View>
                  {/* <HTML html={recommendation} /> */}
                  <pre>{recommendation}</pre>
                </View>
              }
            </View>
          )})}
      </View>
    )
  }
  showCutoffs = (measure, value) => {
    const cutoffs = JSON.parse("[" + this.props.msr.cutoffs + "]")
    const nextLevel = cutoffs[0] && getNextCuttoff(cutoffs[0], value, measure)
    return (
      <Text style={{ color: 'gray' }}>
        {nextLevel}
      </Text>
    )
  }
}

const obValue = ({ display, optionables, unit }, value) => {
  if (display == 'Date') return moment(value * 1000).format('MMMM Do YYYY')
  if (optionables.length) {
    const text = find(optionables, {value})
      ? find(optionables, {value}).option.text
      : Math.max(Math.round(value * 10) / 10).toFixed(1)
    return text
  }
  return `${ Math.max(Math.round(value * 10) / 10).toFixed(1)} ${unit ? unit.plural : ''}`
}

const getNextCuttoff = (cutoffs, value, measure) => {
  // console.log(measure, 'measure')
  // console.log(cutoffs, 'cutoffs')
  const lowOrHigh = cutoffs[0] - cutoffs[3]
  const high = lowOrHigh > 0
  if (high) {
    const highs = cutoffs.filter(num => num > value)
    const val = highs[highs.length - 1]
    return value >= cutoffs[0] ? 'Client has achieved maximum efficiency for this measure' : `Getting ${obValue(measure, val)} is the client's next step up to a higher score.`
  } else {
    const lows = cutoffs.filter(num => num < value)
    const val = lows[lows.length - 1]
    return value <= cutoffs[0] ? 'Client has achieved maximum efficiency for this measure' : `Getting ${obValue(measure, val)} is the client's next step up to a higher score.`
  }
}
