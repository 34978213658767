import { useQuery } from '@apollo/client'
import { assessment } from '../../graphql/query/assessment'
import { scoreInfo, heartLegend, startLegend, setPosition } from '../../helpers/assessment'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import Measure from './measure'
import style from './style'

const Summary = props => {
  const { history, location } = props
  if (!location.state) {
    history.push('/assessments')
    return null
  } else {
    const { loading, error, data } = useQuery(assessment, {
      variables: { id: location.state && location.state.assessment.id },
      fetchPolicy: "network-only"
    })
    if (loading) return <ActivityIndicator size="large" style={{ padding: 20 }} />
    if (error) return <Text>Something went wrong, please try again.</Text>

    const { assessment_type, score, created_at, msrs, obs } = data && data.assessment
    const assessInfo = scoreInfo(score, assessment_type)
    const sortMsrs = sortBy(setPosition(msrs), 'position')
    const scoreMsrs = assessment_type._assessment_types.name === 'Outcome_Measures' ? msrs : filter(msrs, 'weight')
    const renderLegend = () => {
      const legend = assessment_type.heart ? heartLegend : startLegend
      return (
        <View style={style.legendContainer}>
          {legend.map(leg => {
            return (
              <View key={uniqueId()} style={style.legend}>
                <View style={[style.legendColor, { backgroundColor: leg.color }]} />
                <Text style={style.legendLabel}>{leg.label}</Text>
              </View>
            )
          })}
        </View>
      )
    }
    return (
      <View>
        <View style={style.summaryContainer}>
          <TouchableOpacity onPress={() => history.goBack()} style={style.titleBar}>
            <KeyboardArrowLeft htmlColor="black" size={30} />
            <View style={style.titleContainer}>
              {assessment_type.name ? <Text style={style.title}>{assessment_type.name}</Text> : null}
              <Text style={style.createdAt}>{moment(created_at).format('LL')}</Text>
            </View>
          </TouchableOpacity>
          {score
            ? <View style={[style.scoreContainer, { backgroundColor: `${assessInfo.color}` }]}>
                <Text style={style.scoreTxt}>{score}</Text>
              </View>
            : <View style={style.scoreContainer}>
                <Text style={[style.scoreTxt, { fontSize: 12 }]}>SCORE N/A</Text>
              </View>
          }
        </View>
        {assessment_type.description
          ? <Text style={{ color: 'gray', paddingHorizontal: 40, paddingVertical: 20 }}>{assessment_type.description}</Text>
          : null
        }
        {renderLegend()}
        <View>
          {scoreMsrs.map((msr, i, arr) =>
            <Measure
              key={i}
              last={arr.length - 1 === i}
              msr={msr}
              obs={obs}
              assessment_type={assessment_type}
            />
          )}
        </View>
      </View>
    )
  }
}

export default Summary
