import { style } from './style'

export default class extends React.Component {
  render() {
    const {headerLeft, headerTitle, headerRight} = this.props
    return (
      <View style={style.headerContainer}>
        {headerLeft && <View style={style.headerLeft}>
          {headerLeft}
        </View>}
        <View style={style.headerTitle}>
          {headerTitle}
        </View>
        <View style={style.headerRight}>
          {headerRight}
        </View>
      </View>
    )
  }
}
