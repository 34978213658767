import Paper from '@material-ui/core/Paper'
import style from './style'

const content = [
  {
    title: 'OME Basic Introduction',
    description: 'This video will show you how to get started as an OptimumMe user.',
    video_url: 'https://optimum-videos.s3-us-west-2.amazonaws.com/OMENavigation.mp4'
  }
]

export default class Tutorial extends Component {
  render() {
    return (
      <Paper style={{ padding: 20 }}>
        <Text style={style.title}>Tutorials</Text>
        <View style={style.contentContainer}>
          {content.map(guide => {
            return (
              <View key={uniqueId()} style={style.content}>
                <View style={style.titleContainer}>
                  <Text style={style.contentTitle}>{guide.title}</Text>
                </View>
                <video
                  src={guide.video_url}
                  width="298"
                  height="270"
                  controls
                />
              <View style={style.descriptionContainer}>
                  <Text style={style.contentDescription}>{guide.description}</Text>
                </View>
              </View>
            )
          })}
        </View>
        <View style={{ paddingVertical: 20 }}>
          <Text style={style.title}>FAQ</Text>
          <View style={{ paddingLeft: 10 }}>
            <View style={{ paddingTop: 20 }}>
              <Text style={style.faqQ}>Will I see my exercises as soon as I log into the optimumMe app?</Text>
              <Text style={style.faqText}>-You will see your exercises as soon as your Physical Therapist or Trainer has sent them. They will let you know.</Text>
            </View>
            <View style={{ paddingTop: 20 }}>
              <Text style={style.faqQ}>Can I complete an exercise that is in the future?</Text>
              <Text style={style.faqText}>-You can not complete an exercise that is in the future.</Text>
            </View>
            <View style={{ paddingTop: 20 }}>
              <Text style={style.faqQ}>How can I see my updated exercise program?</Text>
              <Text style={style.faqText}>- Pull down the screen to refresh the page to see your updated exercises. </Text>
            </View>
            <View style={{ paddingTop: 20 }}>
              <Text style={style.faqQ}>Do you have to use a smartphone or can you use this program online?</Text>
              <Text style={style.faqText}>- You can also go <a href="https://ome.optimumme.com/login">online</a> to use this program.</Text>
            </View>
            <View style={{ paddingTop: 20 }}>
              <Text style={style.faqQ}>Who/ how do I contact someone if I am having pain or trouble with an exercise?</Text>
              <Text style={style.faqText}>- Fill out the feedback section in the expanded view of the exercise.</Text>
            </View>
            <View style={{ paddingTop: 20 }}>
              <Text style={style.faqQ}>Who/ how do I contact someone if I am having technical difficulties?</Text>
              <Text style={style.faqText}>-Go to the Profile page by clicking on the Cog icon on the bottom of the screen. Here you will see the Help & Feedback link. Click on that link to get help.</Text>
            </View>
          </View>
        </View>
      </Paper>
    )
  }
}

const { height } = Dimensions.get('window')
