import { groupBy, map, partition, concat, compact, reduce } from 'lodash'

export const sortExercises = exercises => {
  const splitExercises = partition(exercises, exr => {
    return exr.__typename === 'OcpWorkoutOb'
  })
  const [ocpExers, ptExers] = splitExercises
  const ptMagic = ptExers.length > 0 && ptExercises(ptExers)
  const combinedExers = concat(ocpExers, ptMagic)
  return compact(combinedExers)
}

const ptExercises = exercises => {
  const groupedExercises = groupBy(exercises, exr => exr.workout_exercise_id)
  const setQtyExers = map(groupedExercises, group => {
    const undoneExer = reduce(group, function(result, value) {
      return !value.done_at ? value : result
    })
    let count = 0
    const getDoneCount = group.map(exr => exr.done_at && count++)
    return { ...undoneExer, qty: group.length, done_count: count }

  })
  return setQtyExers
}
