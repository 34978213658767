import { graphql } from '@apollo/client/react/hoc'
import { Image } from 'react-native-web'
import { Parameters } from './index'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Slider from '@material-ui/core/Slider'
import {style} from './style'
import { workout_exercise } from '../../../graphql/mutation/workout'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import Movie from '@material-ui/icons/Movie'
import Photo from '@material-ui/icons/Photo'
import { mutateExr } from './index'
import SendNote from './sendNote'

export default class extends Component {
  render() {
    const {exr, exr: {done_at, exercise, parameters=[]},
           style: _style, history } = this.props
    return (
      <ScrollView style={[style.detailContainer, _style]}>
        <TouchableOpacity onPress={() => history.goBack()}>
          <View style={style.exrsHeader}>
            <KeyboardArrowLeft htmlColor="black" size={30} />
            <Text numberOfLines={2} style={style.exrsTitle}>
              {exercise.name}
            </Text>
          </View>
        </TouchableOpacity>
        {exercise.image_urls.length > 0 && <WorkoutContent exercise={exercise}/>}
        <Parameters style={style.detailParams}
                    parameters={parameters}/>
        <View style={style.padding}>
          <Text>{exercise.instructions}</Text>
        </View>
        <Rating {...this.props} exr={exr} />
      </ScrollView>
    )
  }
}

@graphql(
  workout_exercise, {
    props: ({mutate, ownProps}) => ({
      rateExr: ob => {
        mutateExr(mutate, { ...ownProps.exr, ...ob })
      }
    })
  }
)
class Rating extends Component {
  state = {
    pain_index: this.props.exr.pain_index,
    difficulty_index: this.props.exr.difficulty_index,
  }
  render() {
    return (
      <View>
        <View style={style.rateContainer}>
          <View style={{width: 85 }}>
            <Text style={{padding: 15}}>Painless</Text>
          </View>
          <Slider
            value={this.state.pain_index}
            min={1}
            max={5}
            step={1}
            onChangeCommitted={this.handleChange('pain_index')}
            marks={true}
          />
          <View style={{width: 85 }}>
            <Text style={{padding: 15}}>Painful</Text>
          </View>
        </View>
        <View style={style.rateContainer}>
          <View style={{width: 85 }}>
            <Text style={{padding: 15}}>Easy</Text>
          </View>
          <Slider
            value={this.state.difficulty_index}
            min={1}
            max={5}
            step={1}
            onChangeCommitted={this.handleChange('difficulty_index')}
            marks={true}
          />
          <View style={{width: 85 }}>
            <Text style={{padding: 15}}>Hard</Text>
          </View>
        </View>
        <SendNote {...this.props} exr={this.props.exr}/>
      </View>
    )
  }
  handleChange = name => (event, value) => {
    this.setState({
      [name]: value,
    })
    this.props.rateExr({
      pain_index: this.state.pain_index,
      difficulty_index: this.state.difficulty_index,
    })
  }
}

class WorkoutContent extends Component {
  state = { photo: true, slide: 0, slides: null }
  componentDidMount() {
    const { exercise } = this.props
    let slides = exercise.image_urls
    slides.push(exercise.video_url)

    this.setState({ slides })
  }
  render() {
    const { exercise } = this.props
    const { photo, slides, slide } = this.state
    return (
      <View>
        <Carousel
          onChange={(slide) => {
            if (slide === slides.length - 1) {

              this.setState({ slide, photo: false })
            } else {
              this.setState({ slide, photo: true })
            }
          }}
          selectedItem={this.state.slide}
          showThumbs={false}
          showStatus={false}
          emulateTouch={true}
          useKeyboardArrows={true}
        >
          {exercise.image_urls[0] &&
            <View style={style.mediaContent}>
              <Image
                resizeMode="contain"
                style={style.mediaContent}
                source={{uri: exercise.image_urls[0]}}
                />
              <View style={style.mediaText}>
                <Text style={{ color: 'white' }}>Start Position</Text>
              </View>
            </View>
          }
          {exercise.image_urls[1] &&
            <View style={style.mediaContent}>
              <Image
                resizeMode="contain"
                style={style.mediaContent}
                source={{uri: exercise.image_urls[1]}}
                />
              <View style={style.mediaText}>
                <Text style={{ color: 'white' }}>End Position</Text>
              </View>
            </View>
          }
          {exercise.video_url &&
            <View style={style.mediaContent}>
              <video width="100%" height="400" src={exercise.video_url} controls />
            </View>
          }
        </Carousel>

        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 15 }}>
          {exercise.image_urls[0] &&
            <TouchableOpacity style={{ marginHorizontal: 15 }} onPress={() => this.setState({ photo: true, slide: 0 })}>
              <Photo htmlColor={photo ? 'black' : 'gainsboro'} style={{ fontSize: 40 }} />
            </TouchableOpacity>
          }
          {exercise.video_url &&
            <TouchableOpacity style={{ marginHorizontal: 15 }} onPress={() => this.setState({ photo: false, slide: slides.length - 1 })}>
              <Movie htmlColor={!photo ? 'black' : 'gainsboro'} style={{ fontSize: 40 }} />
            </TouchableOpacity>
          }
        </View>
      </View>
    )
  }
}
