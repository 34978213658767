import { useMemo, useState } from "react";
import { Mutation } from "react-apollo";
import TextField from "@material-ui/core/TextField";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Button from "@material-ui/core/Button";
import { useQuery } from "@apollo/client";
import { sendMessage } from "../../../graphql/mutation/client";
import { currentClient } from "../../../graphql/query/client";
import { query_thread_by_meta } from '../../../graphql/query/messages';

export default ({ exr, history }) => {
  const { data: userData } = useQuery(currentClient);
  const userUuid = useMemo(() => userData?.currentClient?.uuid, [userData]);
  const [note, setNote] = useState("");
  const [noteSent, setNoteSent] = useState(false);
  const {
    loading: isThreadLoading,
    data: threadDataFromQuery,
  } = useQuery(query_thread_by_meta, {
    variables: { 
      messageable_type: 'EpisodeOfCare',
      messageable_id: exr?.workout?.case_id + '',
      meta: {
        workout_exercise_id: exr?.workout_exercise_id,
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (isThreadLoading) return null;
  if (threadDataFromQuery && threadDataFromQuery?.thread && threadDataFromQuery?.thread?.id) { 
    return (
      <View>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            onPress={() => history.push('/messages', {threadId: threadDataFromQuery?.thread?.id})}
          >
            <Button color="primary">View Conversation</Button>
          </TouchableOpacity>
      </View> 
    )
  }
  return (
    <View>
      <TextField
        label="Send a note to Provider"
        multiline
        rowsMax="4"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        margin="normal"
      />
      <Mutation mutation={sendMessage} onCompleted={() => {}}>
        {(sendMessage) => (
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            onPress={() => {
              if(noteSent) return;
              setNoteSent(true);
              sendMessage({
                variables: {
                  sender: userUuid,
                  receivers: [userUuid],
                  content: note,
                  messageable_type: "EpisodeOfCare",
                  messageable_id: exr?.workout?.case_id + "",
                  priority: 0,
                  message_meta: {
                    exercise_date: exr.scheduled_date,
                    workout_id: exr.workout_id,
                    workout_exercise_id: exr.workout_exercise_id,
                    exercise_name: exr.exercise?.name,
                  },
                  meta: {
                    workout_id: exr.workout_id,
                    workout_exercise_id: exr.workout_exercise_id,
                    exercise_name: exr.exercise?.name,
                  },
                },
              });
            }}
          >
            {noteSent ? (
              <CheckCircle color="primary" style={{ fontSize: 25 }} />
            ) : null}
            <Button color="primary">Send</Button>
          </TouchableOpacity>
        )}
      </Mutation>
    </View>
  );
};
