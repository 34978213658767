import { graphql, Query } from 'react-apollo'
import { AppBar, Toolbar, Drawer, IconButton, useTheme, useMediaQuery } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import UserProfile from './userProfile'
import { 
  omeBar as style,
  drawer as drawerStyle,
} from './style'
import { currentClient } from '../../graphql/query/client'
import Client from './client'
import { useEffect } from 'react'

const CustomDrawer = props => {
  const classes = drawerStyle()
  const theme = useTheme()
  const defaultDrawerOpen = useMediaQuery(theme.breakpoints.up("sm"));
  const shouldAutoCloseDrawer = !defaultDrawerOpen;

  useEffect(() => {
    if ((!props.open && defaultDrawerOpen) || (props.open && !defaultDrawerOpen))
      props.triggerDrawer()
  }, [defaultDrawerOpen])

  return (
    <Drawer
      variant="permanent"
      className={[
        classes.drawer,
        props.open ? classes.drawerOpen : classes.drawerClose
      ].join(' ')}
    >
      <Client 
        {...props}
        triggerDrawer={
          shouldAutoCloseDrawer ? props.triggerDrawer : () => {}
        }
      />
    </Drawer>
  )
}

const Logo = (props) =>
  <View style={{ height: 45 }}>
    <img style={{ height: 40, flex: 1 }} src={props.logo}/>
  </View>


@graphql(currentClient)
export default class extends Component {
  state = {
    drawerOpen: false,
  }
  triggerDrawer = () => this.setState({ drawerOpen: !this.state.drawerOpen })
  render() {
    const { logout } = this.props
    const { drawerOpen } = this.state
    return (
      <Query query={currentClient}>
        {({ data, loading }) => {
          const hasClient = !loading && data && data.currentClient;
          return (<>
            <AppBar style={style.appbar}>
              <Toolbar style={style.toolbar}>
                <View style={style.flex}>
                  {hasClient && (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={this.triggerDrawer}
                      edge="start"
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                  <View style={{ cursor: 'pointer' }}>
                    <Logo logo={this.props.logo} />
                  </View>
                  <View style={style.profileContainer}>
                    {hasClient && (
                      <View style={style.userContainer}>
                        <UserProfile ome={true} user={data.currentClient} logout={logout} />
                      </View>
                    )}
                  </View>
                </View>
              </Toolbar>
            </AppBar>
            {hasClient && (
              <CustomDrawer
                triggerDrawer={this.triggerDrawer}
                open={drawerOpen}
                {...this.props}
              />
            )}
          </>)
        }}
      </Query>
    )
  }
}
