export default {
  page: {
    height: "100%",
  },
  title: {
    paddingBottom: 5,
    display: "flex",
    flexDirection: "row",
    height: 30,
    lineHeight: "30px",
    overflow: "hidden",
  },
  titleText: {
    fontSize: "20px",
    fontWeight: "600",
    height: 30,
    lineHeight: "30px",
  },
  subtitleText: {
    fontSize: "12px",
    height: 30,
    lineHeight: "30px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    flex: 1,
    textWrap: "nowrap",
    marginLeft: 5,
    color: "grey",
  },
  backButtonShrinked: {
    transition: "all 0.2s ease-in-out",
    width: 0,
  },
  backButton: {
    cursor: "pointer",
    transition: "all 0.2s ease-in-out",
    width: 30,
    height: 30,
  },
  threadContainer: {
    padding: 10,
    margin: 10,
    opacity: 0.5,
  },
  threadSelectedContainer: {
    padding: 10,
    margin: 10,
  },
  messageContainerOtherSide: {
    padding: 8,
    margin: "5px auto 5px 5px",
    backgroundColor: "rgb(240, 240, 240)",
    color: "black",
    borderRadius: "10px",
    maxWidth: '50%',
  },
  messageContainerOurSide: {
    padding: 8,
    margin: "5px 5px 5px auto",
    textAlign: "right",
    backgroundColor: "rgb(0, 132, 255)",
    color: "white",
    borderRadius: "10px",
    maxWidth: '50%',
  },
  messageTitleBrief: {
    display: "block",
    lineHeight: "12px",
    fontSize: "10px",
    height: "12px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    whiteSpace: "nowrap",
  },
  messageTextBrief: {
    display: "block",
    lineHeight: "18px",
    fontSize: "14px",
    height: "18px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    whiteSpace: "nowrap",
  },
  messageTextOurSide: {
    display: "block",
    fontSize: "14px",
    color: "white",
  },
  messageTextOtherSide: {
    display: "block",
    fontSize: "14px",
    color: "black",
  },
  messageTime: {
    color: "grey",
    fontSize: "10px",
  },
  messageTimeOurSide: {
    color: "white",
    fontSize: "10px",
  },
  messageTimeOtherSide: {
    color: "grey",
    fontSize: "10px",
  },
  messageExpandIcon: {
    color: "grey",
    position: "absolute",
    top: 0,
    right: 5,
    height: "100%",
  },
  threadsMessagesContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  threadsContainer: {
    flex: 1,
    overflowY: "auto",
  },
  messagesInputBoxContainer: {
    flex: 2,
    overflowY: "hidden",
  },
  hidden: {
    display: "none",
  },
  messagesContainer: {
    flex: 1,
    overflowY: "auto",
    padding: 10,
  },
  ...(() => {
    const inputBoxContainer = {
      padding: 10,
      display: "flex",
      flexDirection: "row",
      transition: "all 0.3s ease-in-out",
      overflow: "hidden",
    };
    const inputBoxContainerHidden = {
      ...inputBoxContainer,
      display: "none",
    };
    const inputBox = {
      flex: 1,
      borderRadius: 0,
      border: "1px solid grey",
      padding: "10px",
      minHeight: 50,
      maxHeight: 150,
      outline: "none",
      resize: 'none',
    };
    const inputBoxSending = {
      ...inputBox,
      color: "grey",
    };
    return {
      inputBoxContainer,
      inputBoxContainerHidden,
      inputBox,
      inputBoxSending,
    };
  })(),
  sendMessageButton: {
    backgroundColor: "rgb(110, 206, 0)",
    width: 100,
    padding: 0,
    borderRadius: 0,
    color: "white",
  },
};
