import { OME_BAR_HEIGHT } from "./home/style";

export default {
  unauthContainer: {
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    marginLeft: 25,
    marginRight: 25
  },
  formContainer: {
    width: 340
  },
  webformContainer: {
    width: 400
  },
  downloadContainer: {
    maxWidth: 600,
    width: '100%'
  },
  content: {
    display: 'flex',
    height: `calc(100% - ${OME_BAR_HEIGHT}px)`,
    marginTop: OME_BAR_HEIGHT,
    flex: 1,
    backgroundColor: '#f4f4f4'
  },
  goals: {
    borderTopWidth: 1,
    borderTopColor: 'gainsboro',
    maxWidth: '100%',
    overflow: 'hidden',
    marginTop: 15
  },
  goalTitle: {
    fontSize: 14,
    fontWeight: '600',
    paddingTop: 15
  },
  goal: {
    alignSelf: 'flex-start',
    padding: 5,
    fontSize: 14,
    textAlign: 'left',
    display: 'flex',
    letterSpacing: 0.5
  },
  goalId: {
    fontWeight: '500',
    color: 'black',
    display: 'inline-block',
    verticalAlign: 'top'
  },
  goalText: {
    wordBreak: 'break-word',
    color: 'gray',
    display: 'inline-block',
    verticalAlign: 'top'
  },
  clientName: {
    paddingTop: 15,
    fontSize: 16,
    fontWeight: '600'
  },
  clientEmail: {
    paddingTop: 5,
    fontSize: 14,
    color: 'gray'
  },
  clientNavBtn: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
    width: 100,
    borderRadius: 7,
    margin: 5,
    cursor: 'pointer'
  },
  navBtnTxt: {
    fontSize: 13,
    color: 'white',
    fontWeight: '600',
    paddingTop: 8
  },
  contentContainer: {
    padding: 20,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    boxSizing: 'border-box'
  }
}
