export default {
  title: {
    fontSize: 24
  },
  contentContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    width: 300,
    margin: 20,
    borderWidth: 1,
    borderColor: 'gainsboro',
    borderRadius: 5
  },
  titleContainer: {
    height: 60
  },
  contentTitle: {
    fontSize: 16,
    fontWeight: '600',
    padding: 20
  },
  descriptionContainer: {
    height: 100
  },
  contentDescription: {
    color: 'gray',
    fontSize: 14,
    padding: 20
  },
  faqQ: {
    fontSize: 16,
    fontWeight: '600'
  },
  faqText: {
    fontSize: 14,
    paddingTop: 10
  }
}
