import { useQuery } from '@apollo/client'
import { currentClient } from '../../graphql/query/client'
import { ome_client_assessments } from '../../graphql/query/assessment'
import LabeledProgressBar from '../common/LabeledProgressBar'
import { scoreInfo } from '../../helpers/assessment'
import style from './style'

const Assessments = props => {
  const { data: userData } = useQuery(currentClient)
  const { loading, error, data } = useQuery(ome_client_assessments, {
    variables: { uuid: userData.currentClient.uuid },
    fetchPolicy: "network-only"
  })
  if (loading) return <ActivityIndicator size="large" style={{ padding: 20 }} />
  if (error) return <Text>Something went wrong, please try again.</Text>
  return (
    <View>
      <Text style={style.title}>My Assessments</Text>
      {data.client_assessments.map(assess => {
        if (data.client_assessments.length === 0) {
          return <Text>You have no assessments.</Text>
        }
        const { assessment_type, created_at, workout, id, score } = assess
        const assessInfo = scoreInfo(score, assessment_type)
        return (
          <TouchableOpacity
            key={id}
            onPress={() => {
              score
                ? props.history.push({
                    pathname: `summary/${id}`,
                    state: { assessment: assess }
                  })
                : props.history.push({
                    pathname: `assessment/${id}`,
                    state: { assessment: id }
                  })
            }}
            style={[style.assessment]}
            
          >
            <View style={style.score}>
              {score
                ? <View style={[style.scoreContainer, { backgroundColor: `${assessInfo.color}` }]}>
                    <Text style={style.scoreTxt}>{score}</Text>
                  </View>
                : <View style={style.scoreContainer}>
                    <Text style={[style.scoreTxt, { fontSize: 12 }]}>SCORE N/A</Text>
                  </View>
              }
              <View style={{ paddingLeft: 20, flex: 1 }}>
                <Text style={style.assessName}>{assessment_type.name}</Text>
                <Text style={style.createdAt}>{moment(created_at).format('LL')}</Text>
              </View>
            </View>
            {workout &&
              <View style={{ width: 250 }}>
                <LabeledProgressBar
                  leftelement={<Text style={style.complianceTxt}>Workout Compliance</Text>}
                  rightelement={<Text style={style.complianceValue}>{`${Math.round(workout.compliance * 100) || 0}%`}</Text>}
                  value={workout.compliance || 0}
                />
              </View>
            }
          </TouchableOpacity>
        )
      })}
    </View>
  )
}

export default Assessments
