import { signup } from '../../graphql/mutation/client'
import { Mutation } from 'react-apollo'
import { reduxForm, Field } from 'redux-form'
import { validatePassword, validateConfirmPassword } from '../../helpers/validation'
import { TextField } from '@material-ui/core'
import { Image } from 'react-native-web'
import { signupStyle as styles } from './style'

@reduxForm({form: 'signup'})
export default class SignupForm extends Component {
  state = { liability: false, terms: false, hipaa: false }
  render() {
    const { user, onSignup, handleSubmit, initialValues } = this.props
    const { liability, terms, hipaa } = this.state
    const agreeToTerms = liability && terms && hipaa
    return (
      <Mutation mutation={signup} onCompleted={onSignup}>
        {(signUp, { loading }) => (
          <View style={styles.container}>
            <Image style={styles.logoImg} source={require('./logo_only.png')} />
            <Header user={user} />
            <FormFields initialValues={initialValues} />
            {this.agreements()}
            <SignupButton
              loading={loading}
              agreeToTerms={agreeToTerms}
              onPress={handleSubmit(variables => signUp({variables}))}
            />
          </View>
        )}
      </Mutation>
    )
  }
  agreements = () => {
    const { liability, terms, hipaa } = this.state
    return (
      <View style={{ paddingTop: 10 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 5 }}>
          <CheckBox
            color="rgb(44, 152, 240)"
            value={liability}
            onValueChange={
              checked => this.setState({ liability: !liability })
            }
          />
          <Text style={{ paddingLeft: 10, fontSize: 12 }}>I have read and agree to the <a target="_blank" href="https://optimumme.com/wp-content/uploads/2020/08/OptimumMe-Waiver-and-Release-of-Liability.pdf">OptimumMe Waiver and Release of Liability</a></Text>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 5 }}>
          <CheckBox
            color="rgb(44, 152, 240)"
            value={terms}
            onValueChange={
              checked => this.setState({ terms: !terms })
            }
          />
          <Text style={{ paddingLeft: 10, fontSize: 12 }}>I have read and agree to the <a target="_blank" href="https://optimumme.com/wp-content/uploads/2020/08/OptimumMe-Terms-of-Use.pdf">OptimumMe Terms of Use</a></Text>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 5 }}>
          <CheckBox
            color="rgb(44, 152, 240)"
            value={hipaa}
            onValueChange={
              checked => this.setState({ hipaa: !hipaa })
            }
          />
          <Text style={{ paddingLeft: 10, fontSize: 12 }}>I have read and agree to the <a target="_blank" href="https://optimumme.com/wp-content/uploads/2020/08/OptimumMe-HIPAA-Terms.pdf">OptimumMe HIPAA Terms</a></Text>
        </View>
      </View>
    )
  }
}

const FormFields = () => {
  const fields = [
    {name: 'token', component: OmeTextInput,
     style: { display: 'none' }},
    {label: 'Create Password', hinttext: 'At least 8 characters including a number',
     name: 'password', password: true,
     component: OmeTextInput, validate: validatePassword},
    {label: 'Confirm Password', name: 'password_confirmation',
     password: true, component: OmeTextInput,
     validate: validateConfirmPassword}
  ]

  return (
    <View>
      {fields.map(field => <Field key={field.name} {...field} />)}
    </View>
  )
}

const Header = ({user: {email}}) => (
  <View>
    <Text style={styles.headerTitle}>Welcome to optimumMe</Text>
    <Text style={styles.user}>{email}</Text>
  </View>
)

const SignupButton = ({loading, onPress, agreeToTerms}) => (
  <TouchableOpacity
    disabled={loading || !agreeToTerms}
    style={loading || !agreeToTerms ? styles.disable : styles.button}
    onPress={onPress}
  >
    {loading
     ? <ActivityIndicator size="small" />
     : <Text style={styles.btnText}>Sign up</Text>}
  </TouchableOpacity>
)


class OmeTextInput extends React.Component {
  render() {
    const { input, style, password, fullWidth, ...inputProps } = this.props
    return (
      <View style={{ paddingVertical: 5 }}>
        <TextField
          {...inputProps}
          style={style && style}
          value={input.value}
          onChange={input.onChange}
          helperText={this.props.hinttext}
          error={this.error(this.props.meta)}
          type={password && "password"}
          fullWidth={true}
        />
      {this.error(this.props.meta) && <Text style={{ color: 'red', paddingTop: 5 }}>{`*${this.props.meta.error}`}</Text>}
      </View>
    )
  }

  error = ({touched, error, warning}) =>
    touched && ((error && error) || (warning && warning))
}
