import { makeStyles } from "@material-ui/core"

export const OME_BAR_HEIGHT = 55;

export const omeBar = {
  appbar: {
    height: OME_BAR_HEIGHT,
    backgroundColor: 'white',
  },
  toolbar: {
    height: OME_BAR_HEIGHT,
    minHeight: OME_BAR_HEIGHT,
  },
  flex: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "row"
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    flexDirection: 'row-reverse',
  },
  userContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  separator: {
    marginRight: 17,
    borderRightWidth: 1,
    borderRightColor: 'gray',
    height: 28
  }
}

export const drawer = makeStyles(theme => {
  const drawerWidth = 300;

  return ({
    drawer: {
      overflowX: 'hidden',
      zIndex: theme.zIndex.appBar - 1,
      flexShrink: 0,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: 0,
      ['& .MuiDrawer-paper']: {
        top: OME_BAR_HEIGHT,
        height: `calc(100% - ${OME_BAR_HEIGHT}px)`,
        width: '100vw',
        boxSizing: 'border-box',
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        ['& .MuiDrawer-paper']: {
          width: drawerWidth,
        },
      },
    },
    drawerOpen: {
      ['& .MuiDrawer-paper']: {
        marginLeft: 0,
      },
    },
    drawerClose: {
      width: 0,
      ['& .MuiDrawer-paper']: {
        marginLeft: '-100%',
      },
    },
  })
})

export const userProfile = {
  name: {
    margin: "2% 0 0 0",
    fontWeight: "600",
    color: "#50534D",
    fontSize: 15
  },
  subtitle: {
    fontSize: 13
  },
  profileView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: "row"
  },
  userInformation: {
    alignItems: 'flex-end',
    marginRight: 10
  },
  submenu: {
    fontSize: 12,
    color: 'gray',
    paddingLeft: 10
  }
}

export const signupStyle = {
  container: {
    width: '100%',
    padding: 25,
    borderRadius: 5,
    display: 'flex',
    alignSelf: 'center',
    backgroundColor: 'white'
  },
  btnText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16
  },
  disable: {
    marginTop: 20,
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(44, 152, 240, 0.3)'
  },
  button: {
    marginTop: 20,
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2C98F0',
    borderRadius: 5
  },
  headerTitle: {
    color: 'black',
    fontWeight: '600',
    fontSize: 20,
    textAlign: 'center',
    marginTop: 10,
  },
  headerText: {
    color: 'black',
    fontSize: 12,
    marginTop: 25,
    textAlign: 'center'
  },
  logoImg: {
    margin: 'auto',
    height: 80,
    width: 80,
    resizeMode: 'contain',
    alignSelf: 'center'
  },
  user: {
    color: 'rgb(24, 169, 169)',
    textAlign: 'center',
    fontWeight: '500',
    fontSize: 16,
    marginTop: 10,
  },
}
