import Avatar from '@material-ui/core/Avatar'
import { userProfile as style } from './style'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import withMediaQuery from '../../helpers/withMediaQuery'

class UserProfile extends Component {
  state = { anchorEl: null }
  render() {
    const { anchorEl } = this.state
    const { user, logout, shouldShowUserName } = this.props
    if (!user) { return null }

    const {first_name, last_name, organization} = user
    const fullName = `${first_name} ${last_name}`

    return (
      <View>
        <TouchableOpacity
          onPress={this.handleClick}
          style={style.profileView}
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
        >
          {
            shouldShowUserName ? (
              <View style={style.userInformation}>
                <Text style={style.name}>{fullName}</Text>
                {organization && <Text style={style.subtTitle}>{organization.name}</Text>}
              </View>
            ) : null
          }
          <Avatar
            innerRef={ref => {this.avatarRef = ref;}}
          >
            {`${first_name[0].toUpperCase()} ${last_name[0].toUpperCase()}`}
          </Avatar>
        </TouchableOpacity>
        {logout &&
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                width: 200
              }
            }}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
          >
            <Text style={style.submenu}>Options</Text>
            <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
          </Menu>
        }
      </View>
    )
  }

  handleLogout = () => {
    this.handleClose()
    this.props.logout()
  }
  handleClick = event => {
    this.setState({ anchorEl: this.avatarRef })
  }
  handleClose = () => {
    this.setState({ anchorEl: null })
  }
}

export default withMediaQuery([
  ['shouldShowUserName', theme => theme.breakpoints.up('sm'), {
    defaultMatches: false
  }]
])(UserProfile);