import Snackbar from '@material-ui/core/Snackbar'
import { toggleSnackbar } from '../actions/snackbar'
import Close from '@material-ui/icons/Close'
import { isString } from 'lodash'

@connect(({snackbar}) => ({...snackbar}), {toggleSnackbar})
export default class extends Component {
  render() {
    const { message, open, toggleSnackbar } = this.props
    return (
      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        autoHideDuration={4000}
        open={open}
        onClose={() => toggleSnackbar()}
        message={<Text style={{ color: 'white' }}>{isString(message) ? message : ''}</Text>}
        action={
          <TouchableOpacity onPress={toggleSnackbar}>
            <Close size={20} style={{fill: "white"}} />
          </TouchableOpacity>
        }
      />
    )
  }
}
