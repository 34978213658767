export default function(state = { open: false }, action) {
  switch(action.type) {
    case 'TOGGLE_SNACKBAR': {
      return Object.assign(
        {}, state, {
          open: action.open || !state.open,
          message: action.message,
        }
      )
    }
  }
  return state
}
