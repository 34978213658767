const VirtualizedList = ({ListEmptyComponent=View, data, children, ...props}) =>
  <ScrollView {...props}>
    {data.length ? children : <ListEmptyComponent/>}
  </ScrollView>

export const SectionList =
  ({sections=[], renderSectionHeader, renderItem: _renderItem, ...props}) =>
    <VirtualizedList {...props}>
      {map(sections, section => {
        const {data, renderItem} = section
        return (
          <View key={uniqueId()}>
            {renderSectionHeader({section})}
            {map(data, item => (renderItem || _renderItem)({item}))}
          </View>
        )
      })}
    </VirtualizedList>
